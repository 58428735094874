<template>
  <span
    class="badge rounded-pill px-3 py-2"
    :class="{
      active: status === 'active' || status === 'confirmed',
      deactivate: status === 'deactivate',
      waiting: status === 'waiting' || status === 'pending',
      'data-verified': status === 'data-verify',
      expired: status === 'expired',
    }"
  >
    <span class="fw-bold" v-if="status === 'active'">Active</span>
    <span class="fw-bold" v-if="status === 'deactivate'">Inactive</span>
    <span class="fw-bold" v-if="status === 'waiting'">Pending</span>
    <span class="fw-bold" v-if="status === 'data-verify'">Pending</span>
    <span class="fw-bold" v-if="status === 'pending'">Pending</span>
    <span class="fw-bold" v-if="status === 'confirmed'">Confirmed</span>
    <span class="fw-bold" v-if="status === 'expired'">Expired</span>
  </span>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
span.badge.deactivate {
  background-color: #fdeaee;
  border: 1px dashed #ed3151;
  color: #ed3151;
}

span.badge.active {
  background-color: #f1f8e7;
  border: 1px dashed #74b50c;
  color: #74b50c;
}

span.badge.waiting {
  background-color: #d9bffa;
  border: 1px dashed #480c94;
  color: #480c94;
}
span.badge.data-verified {
  background-color: #d9bffa;
  border: 1px dashed #480c94;
  color: #480c94;
}
span.badge.loan-limit-verified {
  background-color: #d9bffa;
  border: 1px dashed #480c94;
  color: #480c94;
}
span.badge.expired {
  background-color: #fdeaee;
  border: 1px dashed #ed3151;
  color: #ed3151;
}

.text-yellow {
  color: #f18e16;
}
.text-blue {
  color: #013566;
}
</style>
