<template>
  <table class="table table-bordered table-striped">
    <caption>
      Rincian Nomor
    </caption>
    <tbody>
      <!-- <tr v-if="licenseNumber">
        <td>Nomor Izin Berusaha</td>
        <th scope>{{ licenseFormat(licenseNumber) }}</th>
      </tr>
      <tr v-if="skdpNumber">
        <td>Nomor SKDP (Surat Keterangan Domisili Perusahaan)</td>
        <th scope>{{ licenseFormat(skdpNumber) }}</th>
      </tr> -->
      <!-- <tr v-if="isOpenCompany">
        <td>Perusahaan Terbuka</td>
        <th scope>{{ isOpenCompany ? "Ya" : "Tidak" }}</th>
      </tr> -->
      <tr>
        <td>Nomor NPWP</td>
        <th scope>{{ taxNumber ? taxFormat(taxNumber) : "" }}</th>
      </tr>
      <tr>
        <td>Jenis Approval Link</td>
        <th scope>{{ linkApproval ? "On" : "Off" }}</th>
      </tr>
      <tr>
        <td>Akun Festive</td>
        <th scope>{{ isFestive === "main" ? "Off" : "On" }}</th>
      </tr>
      <tr v-if="financingProgram === 'supplier'">
        <td>Tipe Pencairan</td>
        <th scope>
          {{
            disbursementDateType === 1
              ? "Default Principal"
              : disbursementDateType === 2
              ? "Termasuk Hari Libur"
              : disbursementDateType === 3
              ? "Hari Kerja Saja"
              : ""
          }}
        </th>
      </tr>
      <tr v-if="financingProgram === 'distributor'">
        <td>Tipe Pembayaran</td>
        <th scope>
          {{
            dueDateType === 1
              ? "Default Principal"
              : dueDateType === 2
              ? "Termasuk Hari Libur"
              : dueDateType === 3
              ? "Hari Kerja Saja"
              : ""
          }}
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixin from "@/mixin";

export default {
  mixins: [mixin],
  props: [
    "taxNumber",
    "isOpenCompany",
    "skdpNumber",
    "licenseNumber",
    "linkApproval",
    "isFestive",
    "financingProgram",
    "disbursementDateType",
    "dueDateType",
  ],
};
</script>

<style scoped>
th,
td {
  width: 50%;
  padding: 8px 16px !important;
  height: 56px;
  vertical-align: middle;
}
</style>
