import MainLayout from "@/components/layouts/MainLayout.vue";
import store from "@/store";

const changeMenuManagePrincipal = () => {
  store.dispatch("changeMenu", "management_principal");
};

const principles = [
  {
    path: "/management-principal/principal",
    name: "management_principal.principal.index",
    component: () =>
      import("@/views/management_principal/principal/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Management Principal - Daftar Principal",
      breadcrumbs: [
        {
          name: "Management Principal",
          url: "/",
        },
        {
          name: "Daftar Principal",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManagePrincipal,
  },
  {
    path: "/management-principal/principal/:id",
    name: "management_principal.principal.show",
    component: () =>
      import("@/views/management_principal/principal/ShowView.vue"),
  },
  {
    path: "/management-principal/principal/riwayat-perubahan",
    name: "management-principal.principal.history",
    component: () =>
      import("@/views/management_principal/principal/RiwayatPerubahan"),
    meta: {
      layout: MainLayout,
      pageTitle: "Daftar Principal",
      breadcrumbs: [
        {
          name: "Management Principal",
          url: "/",
        },
        {
          name: "Daftar Principal",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManagePrincipal,
  },
  {
    path: "/management-principal/principal/edit/:id",
    name: "management_principal.principal.edit",
    component: () =>
      import("@/views/management_principal/principal/EditView.vue"),
  },
  {
    path: "/management-principal/principal/update-discounting/:id",
    name: "management_principal.principal.update-discounting",
    component: () =>
      import(
        "@/views/management_principal/principal/UpdateAdditionalFacility.vue"
      ),
  },
  {
    path: "/management-principal/create/principal",
    name: "management_principal.create.principal",
    component: () =>
      import("@/views/management_principal/principal/CreatePrincipal.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Tambah Principal",
      breadcrumbs: [
        {
          name: "Management Principal",
          url: "/",
        },
        {
          name: "Tambah Principal",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManagePrincipal,
  },
];

const overrides = [
  {
    path: "/management-principal/override",
    name: "management_principal.override.index",
    component: () =>
      import("@/views/management_principal/override/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Management Principal - Override",
      breadcrumbs: [
        {
          name: "Management Principal",
          url: "/",
        },
        {
          name: "Override",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManagePrincipal,
  },
  {
    path: "/management-principal/override/:id",
    name: "management_principal.override.show",
    component: () =>
      import("@/views/management_principal/override/ShowView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Pengajuan Kembali",
      isBack: true,
    },
    beforeEnter: changeMenuManagePrincipal,
  },
];

const changeRequest = [
  {
    path: "/management-principal/permohonan-perubahan-principal",
    name: "management_principal.changeRequest.index",
    component: () =>
      import("@/views/management_principal/changeRequest/IndexView.vue"),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        {
          name: "Management Principal",
          url: "/",
        },
        {
          name: "Permohonan Perubahan",
          url: "/",
        },
      ],
    },
    beforeEnter: changeMenuManagePrincipal,
  },
  {
    path: "/management-principal/riwayat-perubahan",
    name: "management_principal.changeRequest.PrincipalChangeHistoryView",
    component: () =>
      import(
        "@/views/management_principal/changeRequest/PrincipalChangeHistoryView.vue"
      ),
    meta: {
      layout: MainLayout,
      pageTitle: "Permohonan Perubahan",
      breadcrumbs: [
        { name: "Management Principal", url: "/" },
        { name: "Riwayat Permohonan Perubahan", url: "/" },
      ],
      isBack: true,
    },
    beforeEnter: changeMenuManagePrincipal,
  },
];

export default [...principles, ...overrides, ...changeRequest];
